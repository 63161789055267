var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":"Editar Tipo de Processo"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Código","label-for":"codigo"}},[_c('validation-provider',{attrs:{"name":"Código","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"codigo","type":"text","maxlength":"20"},model:{value:(_vm.tipoProcesso.codigo),callback:function ($$v) {_vm.$set(_vm.tipoProcesso, "codigo", $$v)},expression:"tipoProcesso.codigo"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalTiposProcesso}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":"Descrição","label-for":"h-descricao"}},[_c('validation-provider',{attrs:{"name":"Descrição","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-descricao","placeholder":"Descrição","maxlength":"50"},model:{value:(_vm.tipoProcesso.descricao),callback:function ($$v) {_vm.$set(_vm.tipoProcesso, "descricao", $$v)},expression:"tipoProcesso.descricao"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":"Tipo Documento","label-for":"tipoDoc"}},[_c('validation-provider',{attrs:{"name":"tipoDoc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"tipoDoc","type":"text"},model:{value:(_vm.tipoDocProcesso.descricao),callback:function ($$v) {_vm.$set(_vm.tipoDocProcesso, "descricao", $$v)},expression:"tipoDocProcesso.descricao"}}),_c('b-input-group-prepend',[_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.showModalTipoDoc}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Duração Prevista","label-for":"h-duracaoPrevista"}},[_c('validation-provider',{attrs:{"name":"duracaoPrevista","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"duracaoPrevista","raw":false,"options":_vm.optionsDuracao.number,"placeholder":"0"},model:{value:(_vm.tipoProcesso.duracaoPrevista),callback:function ($$v) {_vm.$set(_vm.tipoProcesso, "duracaoPrevista", $$v)},expression:"tipoProcesso.duracaoPrevista"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"Permite Agendamento","label-for":"permiteAgendamento","label-class":"mb-1"}},[_c('b-form-checkbox',{attrs:{"id":"permiteAgendamento","value":"1"},model:{value:(_vm.tipoProcesso.permiteAgendamento),callback:function ($$v) {_vm.$set(_vm.tipoProcesso, "permiteAgendamento", $$v)},expression:"tipoProcesso.permiteAgendamento"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){return _vm.switchLoc()}}},[_vm._v(" Gravar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"reset","variant":"outline-secondary"}},[_vm._v(" Cancelar ")])],1)],1)],1)],1),_c('b-modal',{ref:"modalTiposProcesso",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarTipoProcesso',{on:{"tipoProcessoSelecionado":_vm.selectTiposProcesso}})],1),_c('b-modal',{ref:"modalTipoDoc",attrs:{"hide-footer":"","size":"xl"}},[_c('selecionarDocumentoProcesso',{on:{"documentosProcessoSelecionado":_vm.selectTipoDoc}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }